// src/utils/encryption.js
import CryptoJS from 'crypto-js';

const key = '6HA&)NZ*VsjwW8bJ';
const options = {
  mode: CryptoJS.mode.ECB,
  padding: CryptoJS.pad.Pkcs7,
};

// 定义加密函数
export function encryptData(data) {
  const dataBytes = CryptoJS.enc.Utf8.parse(data);
  const keyBytes = CryptoJS.enc.Utf8.parse(key);
  const encrypted = CryptoJS.AES.encrypt(dataBytes, keyBytes, options);
  return encrypted.toString();
}

export function decryptData(data) {
  let key2 = CryptoJS.enc.Utf8.parse(key);
  let decrypt = CryptoJS.AES.decrypt(data, key2, options);
  return CryptoJS.enc.Utf8.stringify(decrypt);
}
